body {
    background-color: #007bff;
}

@font-face {
    font-family: visby ;
    src: url('../fonts/visby.otf');
  }

.marginOff{
    margin: 0;
}

.fullHeight{
    height: 100vh;
}

.bodyFont{
    font-family: visby
}

.paddingOff{
   padding: 0px;
}

.navbar-nav .nav-item .nav-link {
    font-size: 1.2rem;
    margin-left: 30px;
    color: #fff;
}

.start {
    background-color: #fff;
    color: #007bff;
    /* padding: 10px 20px; */
    font-size: 1.2rem;
    border: none;
    border-radius: 25px;
}

.formfield{                    
    background-Color: rgba(255, 255, 255, 0.3) !important;
    border: none !important;
    color: white !important;
    padding: 0 20px !important;
    border-Radius: 50px !important;
    height: 52px !important;                
                            
}

.formfield::placeholder{                    
    color: white !important; 
    font-family: visby;  

}

.headingSize{
    font-size: 3rem;
    font-weight: 500;
    margin-top: 3%;
}

.atc-btn{
    background-Color: white;
    color: #000000;
    border: 2px solid #007bff;
    border-Radius: 100px;
    padding: 0.9% 3% ;
}

.underline{
    position: relative;
    right: -29%;
    width: 225px
}

.navbar-brand img {
    width: 50px;
    border-radius: 50%;
    border: 3px solid #FFD700;
}

.btn-light {
    background-color: #fff;
    border: none;
    color: #007bff;
    font-weight: bold;
    padding: 10px 20px;
}

.banner {
    color: #fff;
    text-align: center;
    padding: 50px;
    position: relative;
    overflow: hidden;
    background-Color: white;
    padding: 60px 0;
 
    background-Image: url(../images/rectangle.png);
    background-Size: cover;
    background-Position: center bottom;
    height: 100vh
}

.transparent-image img {
    width: 100%;
    object-fit: contain;
}

.input-custom {
    padding: 10px;
    font-size: 1rem;
    outline: none;
}

.btn-custom {
    transition: background-color 0.3s ease;
}

.btn-custom:hover {
    background-color: #007bff;
    color: white;
}

.input-position{
    position: relative;
    z-index: 2
}

.andrewHolder{
    position: absolute;
     bottom: 0; 
     left: 50%;
     transform: translateX(-50%);
     z-index: 2;
}

.andyWidth{
    width:300px !important;
}


@media (max-width:1400px){ 
    .underline {
        
        right: -22%;

    }
}

@media (max-width:1167px){ 
    .underline {
        
        right: -13%;

    }
}

@media (max-width:1025px){ 
    .underline {
        
        right: -17%;

    }

    .headingSize{
        font-size: 2.5rem
    }
}


@media (max-width:992px){
    .lead{
        font-size: .95rem
    }



    .headingSizeMob{
    
       font-weight: 600;
        margin-top: 3%;
        line-height: 2.2rem !important;
    }


    .atc-btn {
        padding: 2% 10%;
    }

    .underline {  
        right: -16%;

    }

    .formfield{                    
        border: 1px solid white !important;        
    }

    .andyWidth{
        width: 220px !important;
    }
    .marginOffMob{
        margin: 0 !important;
    }

  

    .banner{
        height: 87vh !important
    }

}


@media (max-width:869px){
    .headingSize{
        font-size:2.3rem
    }

    .underline {  
        right: -11%;

    }
}

@media (max-width:808px){
    .headingSize{
        font-size:2rem
    }

    .underline {  
        right: -11%;

    }
}


@media (max-width:710px){
    .headingSize{
        font-size:1.8rem
    }

    .underline {  
        right: -11%;

    }
}


@media (max-width:653px){
    .headingSize{
        font-size:1.6rem
    }

    .underline {  
        right: -2%;

    }
}


@media (max-width:590px){
    .headingSize{
        font-size:1.4rem
    }

    .underline {  
        right: -2%;
        bottom: 7%;

    }
}